<template>
  <BasePage disableFooter disablePreFooter>
    <template v-slot:content>
      <section>
        <v-container>
          <v-row class="mb-4">
            <v-col
              cols="12"
              sm="12"
              md="10"
              offset-md="1"
              lg="9"
              offset-lg="2"
            >
              <h1 class="-sci-gradient mb-0" v-if="!$auth.newPasswordRequired">Virtual Lab</h1>
              <h2 class="-sci-gradient mb-4" v-else-if="$auth.newPasswordRequired">Reset your password</h2>
              <p v-if="!$auth.newPasswordRequired">Insert your email and password to enter the Regi Virtual Lab</p>
              <p v-else>The password must contain a minimum of 8 characters,<br>at least 1 number, 1 uppercase, 1 lowercase.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="5"
              offset-md="1"
              lg="4"
              offset-lg="2"
            >
              <Form ref="form" key="login" action="javascript:void(0);" v-if="!$auth.newPasswordRequired">
                <InputText
                  required
                  label="Email"
                  name="email"
                  placeholder="Your email"
                  v-model="email"
                  :rules="$auth.emailRules"
                />
                <InputText
                  required
                  type="password"
                  label="Password"
                  name="password"
                  placeholder="Your password"
                  v-model="password"
                  :rules="$auth.passwordRules"
                />
                <Button class="mb-6" @click="signIn" type="submit" :disabled="$auth.working">
                  Login
                </Button>
                <div>
                  <Cta colorSchema="none" action="internal" :to="{name: 'ForgotPasswordPage'}" label="Forgot Password?" />
                </div>
              </Form>
              <Form ref="newPasswordForm" key="reset" action="javascript:void(0);" v-else>
                <InputText
                  required
                  type="password"
                  label="New Password"
                  name="password"
                  placeholder="New password"
                  v-model="newPassword"
                  :rules="$auth.passwordRules"
                />
                <InputText
                  required
                  type="password"
                  label="Confirm the New Password"
                  name="password"
                  placeholder="New password"
                  v-model="confirmNewPassword"
                  :rules="$auth.passwordRules.concat(passwordConfirmRule)"
                />
                <Button class="mb-4" @click="changePassword" type="submit" :disabled="$auth.working">
                  Confirm
                </Button>
              </Form>
            </v-col>
            <v-col
              v-show="!$auth.newPasswordRequired"
              cols="12"
              sm="5"
              offset-sm="1"
              md="4"
              offset-md="1"
              class="mt-8 mt-sm-0"
            >
              <h5 class="text-bold">I would like to be a part of the virtual lab</h5>
              <p>This private section contains the news and details on our latest collections and launches<br>To find out how to gain access</p>
              <Cta colorSchema="none" action="send-email"  label="Write us" />
            </v-col>
          </v-row>
        </v-container>
      </section>
    </template>
  </BasePage>
</template>

<script>
import BasePage from '@/organisms/BasePage'
import Cta from '@/molecules/Cta'
import Button from '@/atoms/Button'
import Form from '@/atoms/Form'
import InputText from '@/atoms/InputText'
import PageSeoQuery from '@/graphql/seo/GetLoginPageSeo.gql'
import { optimizedImageURL } from '@/services/OptimizedImage'

import trim from 'lodash/trim'

export default {
  name: 'LoginPage',

  props: {
    invitationEmail: {
      type: String,
      required: false,
      default: null
    },
    invitationTemporaryPassword: {
      type: String,
      required: false,
      default: null
    }
  },

  components: {
    BasePage,
    Cta,
    Button,
    Form,
    InputText
  },

  apollo: {
    PageSeo: {
      query: PageSeoQuery,
      variables: {
        slug: 'globals'
      }
    }
  },

  data () {
    return {
      email: this.invitationEmail,
      password: this.invitationTemporaryPassword,
      newPassword: null,
      confirmNewPassword: null,
      passwordConfirmRule: v => v === this.newPassword || 'Passwords must match'
    }
  },
  mounted () {
    if (this.$auth.user) {
      this.$router.push(this.$route.query.to || { name: 'Home' })
    }

    if (this.invitationEmail && this.invitationTemporaryPassword) {
      this.signIn()
    }
  },
  methods: {
    async signIn () {
      if (this.$refs.form.validate()) {
        try {
          await this.$auth.signIn(trim(this.email).toLowerCase(), this.password)
        } catch (e) {
          console.error(e)
        }
      }
    },
    async changePassword () {
      if (this.$refs.newPasswordForm.validate()) {
        try {
          await this.$auth.completeNewPassword(this.$auth.cognitoUser, this.newPassword)
        } catch (e) {
          console.error(e)
        }
      }
    }
  },

  watch: {
    '$auth.user' (value) {
      if (value) {
        this.$router.push(this.$route.query.to || { name: 'Home' })
      }
    }
  },

  metaInfo: function () {
    if (this.PageSeo) {
      let meta = [
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: this.PageSeo.loginMetaTitle },
        { vmid: 'description', name: 'description', content: this.PageSeo.loginMetaDescription },
        { vmid: 'ogdescription', property: 'og:description', content: this.PageSeo.loginMetaDescription }
      ]

      if (this.PageSeo.loginMetaImage) {
        meta = meta.concat([
          { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + optimizedImageURL(this.PageSeo.loginMetaImage, { width: 1200, height: 625, format: 'jpg', fit: 'crop' }) },
          { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
          { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
          { vmid: 'ogimagewidth', property: 'og:image:height', content: '675' }
        ])
      }

      return {
        title: this.PageSeo.loginMetaTitle,
        meta
      }
    }
  }
}
</script>

<style scoped lang="scss">
section {
  height: 100%;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
}
</style>
